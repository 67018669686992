<template>
  <div class="containerPrincipal">
    <div class="containerLogin">
      <h1>Sistema de votação</h1>
      <div>
        <button class="buttonLogin" type="button" @click="() => this.exibirModalCriarSala = true">Criar sala</button>
        <button class="buttonLogin" type="button" @click="() => this.exibirModalEntrarNaSala = true">Entrar</button>
      </div>
      <v-dialog v-model="exibirModalCriarSala" width="80vw" max-width="500px">
        <div style="background-color:white" class="containerCriarSala">
        <h3>Criar sala</h3>
            <input class="inputNome" placeholder="Digite seu nome" type="text" v-model="nomeLogin" />
            <div>
              <button class="button" type="button" @click="this.criarSala">Criar</button>
              <button class="button" type="button" @click="() => this.exibirModalCriarSala = false">Cancelar</button>
            </div>
        </div>
      </v-dialog>
  
      <v-dialog v-model="exibirModalEntrarNaSala" width="80vw" max-width="500px">
        <div style="background-color:white" class="containerCriarSala">
        <h3>Entrar</h3>
        <input type="text" class="inputNome" placeholder="Digite seu nome" v-model="nomeLogin" />
        <input type="text" class="inputNome" placeholder="Digite o token da sala" v-model="tokenLogin" />
        <div>
          <button class="button" @click="this.entrar">Entrar</button>
          <button class="button" type="button" @click="() => this.exibirModalEntrarNaSala = false">Cancelar</button>
        </div>
        </div>
      </v-dialog>
    </div>
  </div>
</template>

<script>
import votacao from '../services/votacao.service';
export default {
  name: 'loginVotacao',
  data() {
    return {
      exibirModalCriarSala: false,
      exibirModalEntrarNaSala: false,
      nomeLogin: null,
      tokenLogin: null,
    }
  },
  methods: {
    async criarSala(){
      console.log(this.nomeLogin)
      const obj = {
        nome: this.nomeLogin
      }
      await votacao.criarSala(obj)
      .then((res) => {
        localStorage.setItem('sala', JSON.stringify(res.data));
        this.$router.push('/votacao')
      })
      .catch((error) => {
        console.log('erro ao criar sala', error)
      })
    },

    async entrar() {
      const obj = {
        nome: this.nomeLogin,
        token: this.tokenLogin
      }
      console.log(obj)
      await votacao.entrar(obj)
      .then((res) => {
        localStorage.setItem('sala', JSON.stringify(res.data));
        this.$router.push('/votacao')
      })
      .catch((error) => {
        console.log('erro ao criar sala', error)
      })
    },
  },

  async mounted(){
   console.log('montado');
  }
}
</script>

<style scoped>
.containerPrincipal {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 90vh;
}

.containerLogin {
  background-color: #1377F2;
  width: 30%;
  height: 20%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
}

.buttonLogin {
  background-color: white;
  color: black;
  width: 100px;
  height: 40px;
  border-radius: 5px;
  margin: 5px;
}

h1 {
  color: white;
}

.containerCriarSala {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

.inputNome {
  background-color: white;
  border: 1px solid black;
  border-radius: 5px;
  width: 85%;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.button {
  background-color: #1377F2;
  color: white;
  width: 100px;
  height: 30px;
  border-radius: 5px;
  margin: 5px;
}
</style>
